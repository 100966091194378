import * as React from "react";

function MessageLikeIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      className="prefix__r-111h2gw prefix__r-4qtqp9 prefix__r-yyyyoo prefix__r-1q142lx prefix__r-50lct3 prefix__r-dnmrzs prefix__r-bnwqim prefix__r-1plcrui prefix__r-lrvibr prefix__r-1srniue"
      {...props}
    >
      <path d="M22.436 16.92H19.76v-2.673a.749.749 0 10-1.5 0v2.674h-2.676c-.413 0-.75.337-.75.75s.337.75.75.75h2.677v2.677c0 .413.337.75.75.75s.75-.337.75-.75V18.42h2.676a.75.75 0 000-1.5z" />
      <path d="M11.088 21.214c-2.61-.046-10.064-6.778-10.064-13.157 0-3.066 2.525-5.755 5.404-5.755 2.29 0 3.83 1.582 4.646 2.73.816-1.148 2.357-2.73 4.646-2.73 2.878 0 5.403 2.69 5.403 5.755 0 1.13-.232 2.323-.69 3.545a.75.75 0 11-1.404-.526c.394-1.053.594-2.07.594-3.02 0-2.266-1.824-4.254-3.903-4.254-2.525 0-3.94 2.937-3.952 2.966-.23.562-1.155.562-1.387 0-.01-.03-1.425-2.966-3.952-2.966-2.08 0-3.904 1.988-3.904 4.255 0 5.76 7.076 11.63 8.563 11.657.168-.003.764-.195 1.758-.892a.75.75 0 01.861 1.229c-1.085.76-1.966 1.153-2.618 1.164z" />
    </svg>
  );
}

export default MessageLikeIcon;
