import * as React from "react";

function SharePostIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      className="prefix__r-4qtqp9 prefix__r-yyyyoo prefix__r-1xvli5t prefix__r-dnmrzs prefix__r-bnwqim prefix__r-1plcrui prefix__r-lrvibr prefix__r-1hdv0qi"
      {...props}
    >
      <path d="M17.53 7.47l-5-5a.749.749 0 00-1.06 0l-5 5a.749.749 0 101.06 1.06l3.72-3.72V15a.75.75 0 001.5 0V4.81l3.72 3.72c.146.147.338.22.53.22s.384-.072.53-.22a.749.749 0 000-1.06z" />
      <path d="M19.708 21.944H4.292A2.294 2.294 0 012 19.652V14a.75.75 0 011.5 0v5.652c0 .437.355.792.792.792h15.416a.793.793 0 00.792-.792V14a.75.75 0 011.5 0v5.652a2.294 2.294 0 01-2.292 2.292z" />
    </svg>
  );
}

export default SharePostIcon;
