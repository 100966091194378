const messages = [
  {
    fromto: "mucahitsahin6-cnecati",
    messages: [
      {
        message: "merhaba",
        from: "mucahitsahin6",
      },
      {
        message: "merhaba",
        from: "cnecati",
      },
      {
        message: "merhaba",
        from: "cnecati",
      },
      {
        message: "merhaba",
        from: "mucahitsahin6",
      },
    ],
  },
  {
    fromto: "mucahitsahin6-yasin",
    messages: [
      {
        message: "merhaba",
        from: "mucahitsahin6",
      },
      {
        message: "merhaba",
        from: "yasin",
      },
      {
        message: "merhaba",
        from: "yasin",
      },
      {
        message: "merhaba",
        from: "mucahitsahin6",
      },
      {
        message: "merhaba",
        from: "mucahitsahin6",
      },
      {
        message: "merhaba",
        from: "mucahitsahin6",
      },
    ],
  },
];

export default messages;
