import * as React from "react";

function ChatIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      className="prefix__r-jwli3a prefix__r-4qtqp9 prefix__r-yyyyoo prefix__r-1q142lx prefix__r-1blvdjr prefix__r-1xvli5t prefix__r-dnmrzs prefix__r-bnwqim prefix__r-1plcrui prefix__r-lrvibr"
      data-selected="true"
      data-label-id={0}
      {...props}
    >
      <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828a.85.85 0 00.12.403.744.744 0 001.034.229c.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67a.75.75 0 00-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z" />
    </svg>
  );
}

export default ChatIcon;
